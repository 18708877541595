body, html {
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    background-color: #0b0709;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
}

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

#root {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-top: 0px;
}

a {
    text-decoration: none;
}


.paper {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    margin: 10px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.paper h5 {
    color: #333333;
}

.paper p {
    color: #666666;
}

.MuiTypography-root.footer-links,
.MuiTypography-root .footer-links {
    color: rgb(152, 147, 156);
    text-decoration: none;
}

.MuiTypography-root.footer-links:hover,
.MuiTypography-root .footer-links:hover {
    color: rgb(255, 0, 122);
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
}

.main-underline {
    display: inline;
    background-image: -webkit-linear-gradient(120deg, #00e5ff 0%, #00e5ff 100%);
    background-image: -o-linear-gradient(120deg, #00e5ff 0%, #00e5ff 100%);
    background-image: linear-gradient(120deg, #00e5ff 0%, #00e5ff 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.15em;
    background-position: 0 91%;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    font-weight: 900;
}

.pink-underline {
    display: inline;
    background-image: -webkit-linear-gradient(120deg, #ff007a 0%, #ff007a 100%);
    background-image: -o-linear-gradient(120deg, #ff007a 0%, #ff007a 100%);
    background-image: linear-gradient(120deg, #ff007a 0%, #ff007a 100%);
    background-repeat: no-repeat;
    background-size: 100% 0.15em;
    background-position: 0 91%;
    -webkit-transition: all .2s ease-in;
    -moz-transition: all .2s ease-in;
    -o-transition: all .2s ease-in;
    transition: all .2s ease-in;
    font-weight: 900;
}

.pink {
    color: rgb(255, 0, 122);
}

.MuiPopper-root.MuiAutocomplete-popper,
.MuiPopper-root.MuiAutocomplete-popper * {
    color: rgb(255, 255, 255);
    background-color: rgb(51, 51, 51);
}

.MuiButton-root.mainButton {
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%);
    border: 1px solid #252527;
    box-shadow: 0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5);
    border-radius: 37px;
    min-height: 40px;
    color: white;
    font-weight: bold;
    padding: 10px 40px; /* Add padding for a nice size */
    cursor: pointer; /* Change cursor on hover */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
}

.MuiButton-root.mainButton:hover {
    background-color: rgb(200, 0, 100); /* Slightly darker shade for hover */
}

.MuiButton-root.mainButton:disabled {
    background-color: rgb(152, 147, 156); /* Disabled button background */
    color: #9c9b93; /* Disabled button text color */
    cursor: not-allowed; /* Disabled cursor */
}

.MuiButton-root.MuiButton-text {
    text-transform: none;
}

._9pm4ki5 > div > div {
    border-radius: 32px !important;
}

.iekbcc0.iekbcc9.ju367v77.ju367v7s.ju367v88.ju367v6h.ju367vc6.ju367vt.ju367vv.ju367vm.ju367v8.ju367v2u.ju367v8v.ju367v9i.ju367v2b._12cbo8i3.ju367v8r._12cbo8i4._12cbo8i7 {
    background: linear-gradient(0deg, rgba(40, 40, 40, 0.2), rgba(40, 40, 40, 0.2)), radial-gradient(49% 121.06% at 49% 100%, #FF007A 0%, rgba(255, 0, 122, 0) 100%);
    border: 1px solid #252527;
    box-shadow: 0px 10px 33.9px rgba(255, 255, 255, 0.06), inset 0px 0px 13px rgba(255, 0, 122, 0.5);
    border-radius: 37px;
    min-height: 40px;
}

.iekbcc0.iekbcc9.ju367v77.ju367v7s.ju367v88.ju367v6h.ju367vc6.ju367vt.ju367vv.ju367vm.ju367v8.ju367v2u.ju367v8v.ju367v9u.ju367v2b {
    border: 1px solid #252527;
    border-radius: 37px;
    min-height: 40px;
}

.newLockBg {
    background: linear-gradient(120deg, rgba(46,13,29,1) 0%, rgba(21,12,16,0) 100%);
    border-radius: 16px;
    margin-bottom: 20px;
}

.locksTableRow {
    background: linear-gradient(120deg, rgba(46,13,29,1) 0%, rgba(18,11,11,0) 100%);
    border-radius: 16px;
    display: flex !important;
    margin-bottom: 20px;
}

.locksTableRow td {
    border: 0;
}

.locksTable {
    border-collapse: initial !important;
}

.locksTable thead tr {
    display: block !important;
}

.mainSVG {
    max-width: 100%;
    visibility: hidden;
}

.conditionsSmall {
    font-size: 0.8rem;
    color: #9c9b93;
}